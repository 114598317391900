import React  from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/hr/layout"
import SEO from "../components/seo"
import Carousel from "react-bootstrap/Carousel"
import Img from "gatsby-image/withIEPolyfill"
import Button from "react-bootstrap/Button"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
      query {
          seo: directusCompanySeo(language: {eq: "hr"}) {
              title
              description
          }
          sections: allDirectusHomeTranslation(filter: {language: {eq: "hr"}}, sort: {fields: home___order, order: ASC}) {
              nodes {
                  title
                  text
                  pre_headline
                  image {
                      localFile {
                          childImageSharp {
                              fluid {
                                  ...GatsbyImageSharpFluid
                              }
                          }
                      }
                  }
                  mobile_image {
                      localFile {
                          childImageSharp {
                              fluid(maxWidth: 2000, maxHeight: 3000, cropFocus: CENTER) {
                                  ...GatsbyImageSharpFluid
                              }
                          }
                      }
                  }
                  link_image {
                      localFile {
                          childImageSharp {
                              fluid {
                                  ...GatsbyImageSharpFluid
                              }
                          }
                      }
                  }
                  link_url
                  link_text
                  home {
                      order
                  }
              }
          }
      }
  `)

  return (
    <Layout>
      <SEO title={ data.seo.title } description={ data.seo.description } lang={"hr"} url={"/"} />
      <div className="home-xs-header d-md-none d-sm-none d-block">
        <Img className="d-block h-100" fluid={(data.sections.nodes[0].mobile_image || data.sections.nodes[0].image).localFile.childImageSharp.fluid}/>
        <div className="title-xs">
          <p className="main-caption">{data.sections.nodes[0].pre_headline}</p>
          <h1 className="main-title">{data.sections.nodes[0].title}</h1>
        </div>
      </div>
      <div className="d-md-block d-sm-block d-none">
        <Carousel controls={false} fade>
          { data.sections.nodes.map((section, key) => (
            <Carousel.Item key={key}>
              <Img className="d-block w-100" fluid={section.image.localFile.childImageSharp.fluid}/>
              <Carousel.Caption>
                <div className="row">
                  <div className="box-home-title">
                    <p className="main-caption hidden-sm">{ section.pre_headline }</p>
                    <h1 className="main-title">{ section.title }</h1>
                    <div className="main-paragraph" dangerouslySetInnerHTML={{ __html: section.text }}/>
                    { section.link_url ? (
                      <Button className={"float-right"} href={section.link_url}>
                        { section.link_image ? (
                          <Img className="d-block" fluid={section.link_image.localFile.childImageSharp.fluid} objectFit="fill"/>
                        ) : (<>{ section.link_text }</>)}
                      </Button>
                    ) : (<></>)}
                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </Layout>
  )
}

export default IndexPage
